// import Jobs from "../../user/pages/Jobs";
import TrainingDetail from "../../user/pages/TrainingDetail";
import UserTrainings from "../../user/pages/UserTrainings";
import TrainingProgram from "../../employer/pages/TrainingProgram";
import AddJobPosting from "../../employer/pages/AddJobPosting";
import JobDetail from "../../user/pages/JobDetail";
import AddTraining from "../../employer/pages/AddTraining";
import EmployerProfile from "../../employer/pages/dashboard/EmployerProfile";
import EmployerProfileUpdate from "../../employer/pages/dashboard/EmployerProfileUpdate";
import ChangePassword from "../../components/ChangePassword";
import Applicants from "../../admin/pages/Applicants";
import ViewApplicantProfile from "../../user/pages/dashboard/ViewApplicantProfile";
import AddRole from "../../employer/pages/AddRole";
import AddSponsor from "../../employer/pages/Sponsors ";
import AllSkillsReports from "../../admin/pages/dashboard/AllSkillsReports";
import MySponsoredCourses from "../../employer/pages/MySponsoredCourses";
import EmployerJobListing from "../../employer/pages/EmployerJobListing";
import JobApplicants2 from "../../components/employer/Jobs/JobApplicants2";
import ShortlistedApplicants from "../../employer/pages/ShortlistedApplicants";
import CreateEmployerTeam from "../../employer/pages/CreateEmployerTeam";
import EmployerTeams from "../../employer/pages/EmployerTeams";
import EmployerRolesAndPermissions from "../../employer/pages/EmployerRolesAndPermissions";
import CreateRoleAndPermissions from "../../employer/pages/CreateRoleAndPermissions";
import TrainersCourses from "../../trainers/pages/dashboard/TrainersCourses";
import JobsNew from "../../employer/pages/JobsNew";
import EmployerSponsoredCourseApplicants from "../../employer/pages/EmployerSponsoredCourseApplicants";
import MessageDashboard from "src/components/message/pages/dashboard/messageDashboard";
import ChatBoard from 'src/components/message/pages/dashboard/ChatBoard'

const PrivateEmployerRoutes = [
    {
        path: '/employer/job-listing/',
        exact: true,
        element: EmployerJobListing,
        allowedPermissions: ['Can_Manage_Jobs']
    },
    {
        path: '/employer/job-listing/:jobId',
        exact: true,
        element: JobApplicants2,
        allowedPermissions: ['Can_Manage_Jobs']
    },
    {
        path: '/employer/job-detail/:slug',
        exact: true,
        element: JobDetail,
        allowedPermissions: ['Can_Manage_Jobs']
    },
    {
        path: '/employer/jobs2',
        exact: true,
        element: JobApplicants2,
        allowedPermissions: ['Can_Manage_Jobs']
    },
    {
        path: '/employer/create-job-posting',
        exact: true,
        element: AddJobPosting,
        allowedPermissions: ['Can_Manage_Jobs']
    },
    {
        path: '/employer/jobs',
        exact: true,
        element: JobsNew,
        allowedPermissions: ['Can_Manage_Jobs']
    },

    // {
    //     path: '/employer/jobs-new',
    //     exact: true,
    //     element: Jobs,
    //     allowedPermissions: ['Can_Manage_Jobs']
    // },

    {
        path: '/employer/my-sponsored-courses',
        exact: true,
        element: MySponsoredCourses,
        allowedPermissions: ['Can_Manage_Invoices']
    },
    {
        path: '/employer/my-sponsored-course-applicants/:title/:sponsorshipId',
        exact: true,
        element: EmployerSponsoredCourseApplicants,
        allowedPermissions: ['Can_Manage_Invoices']
    },
    {
        path: '/employer/reports',
        exact: true,
        element: AllSkillsReports,
        allowedPermissions: ['Can_View_Reports']
    },
    {
        path: '/employer/training-detail/:slug',
        exact: true,
        element: TrainingDetail,
        allowedPermissions: ['Can_Manage_Programs']
    },
    {
        path: '/employer/trainings-programs',
        exact: true,
        element: UserTrainings,
        allowedPermissions: ['Can_Manage_Programs']
    },
    {
        path: '/employer/my-programs',
        exact: true,
        element: TrainingProgram,
        allowedPermissions: ['Can_Manage_Programs']
    },
    {
        path: '/employer/sponsor-course/:curseId',
        exact: true,
        element: AddSponsor,
        allowedPermissions: ['Can_Manage_Programs']
    },
    {
        path: '/employer/create-training',
        exact: true,
        element: AddTraining,
        allowedPermissions: ['Can_Manage_Programs']
    },
    {
        path: '/employer/profile',
        exact: true,
        element: EmployerProfile,
        allowedPermissions: ['Can_View_Reports']
    },
    {
        path: '/employer/message',
        exact: true,
        element: MessageDashboard,
        allowedPermissions: ['Can_View_Reports', 'Can_Manage_Programs']
    },
    {
        path: '/employer/message/chat',
        exact: true,
        element: ChatBoard,
        allowedPermissions: ['Can_View_Reports', 'Can_Manage_Programs'],
    },
    {
        path: '/employer/profile-update',
        exact: true,
        element: EmployerProfileUpdate,
        allowedPermissions: ['Can_View_Reports']
    },
    {
        path: '/employer/change-password',
        exact: true,
        element: ChangePassword,
        allowedPermissions: ['Can_View_Reports']
    },
    {
        path: '/employer/applicants',
        exact: true,
        element: Applicants,
        allowedPermissions: ['Can_Manage_Users']
    },
    {
        path: '/employer/add-role/:role',
        exact: true, element: AddRole,
        allowedPermissions: ['Can_Manage_Users']
    },
    {
        path: '/employer/applicant/:uuid',
        exact: true,
        element: ViewApplicantProfile,
        allowedPermissions: ['Can_Manage_Users']
    },
    {
        path: '/employer/applicants/shortlisted',
        exact: true,
        element: ShortlistedApplicants,
        allowedPermissions: ['Can_Manage_Users']
    },
    {
        path: '/employer/create-admin',
        exact: true,
        element: CreateEmployerTeam,
        allowedPermissions: ['Can_Manage_Users']
    },
    {
        path: '/employer/admins',
        exact: true,
        element: EmployerTeams,
        allowedPermissions: ['Can_Manage_Users']
    },
    {
        path: '/employer/role-permissions',
        exact: true,
        element: EmployerRolesAndPermissions,
        allowedPermissions: ['Can_Manage_Users']
    },
    {
        path: '/employer/create-role-permissions',
        exact: true,
        element: CreateRoleAndPermissions,
        allowedPermissions: ['Can_Manage_Users']
    },
    {
        path: '/employer/course',
        exact: true,
        element: TrainersCourses,
        allowedPermissions: ['can_view_jobs']
    },

  {
    path: '/employer/jobs-new',
    exact: true,
    element: JobsNew,
    allowedPermissions: ['Can_Manage_Jobs'],
  },

  {
    path: '/employer/my-sponsored-courses',
    exact: true,
    element: MySponsoredCourses,
    allowedPermissions: ['Can_Manage_Invoices'],
  },
  {
    path: '/employer/reports',
    exact: true,
    element: AllSkillsReports,
    allowedPermissions: ['Can_View_Reports'],
  },
  {
    path: '/employer/training-detail/:slug',
    exact: true,
    element: TrainingDetail,
    allowedPermissions: ['Can_Manage_Programs'],
  },
  {
    path: '/employer/trainings-programs',
    exact: true,
    element: UserTrainings,
    allowedPermissions: ['Can_Manage_Programs'],
  },
  {
    path: '/employer/my-programs',
    exact: true,
    element: TrainingProgram,
    allowedPermissions: ['Can_Manage_Programs'],
  },
  {
    path: '/employer/sponsor-course/:curseId',
    exact: true,
    element: AddSponsor,
    allowedPermissions: ['Can_Manage_Programs'],
  },
  {
    path: '/employer/create-training',
    exact: true,
    element: AddTraining,
    allowedPermissions: ['Can_Manage_Programs'],
  },
  {
    path: '/employer/profile',
    exact: true,
    element: EmployerProfile,
    allowedPermissions: ['Can_View_Reports'],
  },
  {
    path: '/employer/message',
    exact: true,
    element: MessageDashboard,
    allowedPermissions: ['Can_View_Reports', 'Can_Manage_Programs'],
  },
  {
    path: '/employer/profile-update',
    exact: true,
    element: EmployerProfileUpdate,
    allowedPermissions: ['Can_View_Reports'],
  },
  {
    path: '/employer/change-password',
    exact: true,
    element: ChangePassword,
    allowedPermissions: ['Can_View_Reports'],
  },
  {
    path: '/employer/applicants',
    exact: true,
    element: Applicants,
    allowedPermissions: ['Can_Manage_Users'],
  },
  {
    path: '/employer/add-role/:role',
    exact: true,
    element: AddRole,
    allowedPermissions: ['Can_Manage_Users'],
  },
  {
    path: '/employer/applicant/:uuid',
    exact: true,
    element: ViewApplicantProfile,
    allowedPermissions: ['Can_Manage_Users'],
  },
  {
    path: '/employer/applicants/shortlisted',
    exact: true,
    element: ShortlistedApplicants,
    allowedPermissions: ['Can_Manage_Users'],
  },
  {
    path: '/employer/create-admin',
    exact: true,
    element: CreateEmployerTeam,
    allowedPermissions: ['Can_Manage_Users'],
  },
  {
    path: '/employer/admins',
    exact: true,
    element: EmployerTeams,
    allowedPermissions: ['Can_Manage_Users'],
  },
  {
    path: '/employer/role-permissions',
    exact: true,
    element: EmployerRolesAndPermissions,
    allowedPermissions: ['Can_Manage_Users'],
  },
  {
    path: '/employer/create-role-permissions',
    exact: true,
    element: CreateRoleAndPermissions,
    allowedPermissions: ['Can_Manage_Users'],
  },
  {
    path: '/employer/course',
    exact: true,
    element: TrainersCourses,
    allowedPermissions: ['can_view_jobs'],
  },
]

export default PrivateEmployerRoutes
