import React, { useState } from 'react'
import { CButton, CCol } from '@coreui/react-pro'
import JobOverview from './JobOverview'
import JobScreeningQuestion from './JobScreeningQuestion'
import PreviewPublishJob from './PreviewPublishJob'
import CheckedIcon from 'src/assets/icons/CheckedIcon'
import UnCheckedIcon from 'src/assets/icons/UnCheckedIcon'
import { jobSteps } from 'src/constant/constants'
import appApiCalls from 'src/services/api'
import { fail_notify } from 'src/constant'
import JobSuccessModal from './JobSuccessModal'
import { useGetJobScreeningQuestions } from 'src/hooks/employer/employerApiHooks'
import ShowLoading from '../ShowLoading'

const initialQuestions = [
  {
    type: 'degree',
    label: 'What level of Education have you completed?',
    options: ['Yes', 'No'],
    mustHave: false,
    answer: 'Yes',
  },
  {
    type: 'experience',
    label: 'How many years of work experience do you have with skills?',
    options: ['Yes', 'No'],
    mustHave: false,
    answer: 'Yes',
  },
  {
    type: 'background check',
    label: 'Have you completed a background check?',
    options: ['Yes', 'No'],
    mustHave: false,
    answer: 'Yes',
  },
]

const initialValue = {
  company: '',
  jobTitle: '',
  jobType: '',
  jobLocation: '',
  employmentType: '',
  seniorityLevel: '',
  companyIndustry: '',
  jobFunction: '',
  siteToApply: '',
  emailToNotify: '',
  isResumeRequired: false,
  isPublished: false,
  description: '',
  isQualified: false,
  emailTemplate: '',
}

function PostJob({ setActiveTap }) {
  const [jobStep, setjobStep] = useState(jobSteps[0])
  const [SkillOptions, setSkillOptions] = useState([])
  const [skillValue, setSkillValue] = useState('')
  const [fieldValues, setFieldValues] = useState(initialValue)
  const [successfulModal, setSuccessfulModal] = useState(false)

  const { data, isLoading } = useGetJobScreeningQuestions()

  const screeningQuestionData = data?.data?.data
  const transformedQuestion = screeningQuestionData?.map((question, index) => ({
    ...question,
    ...initialQuestions[index],
  }))

  const [questions, setQuestions] = useState([])

  const handleFieldChange = ({ name, value }) => {
    setFieldValues((prev) => ({ ...prev, [name]: value }))
  }

  const handleAnswerChange = (id, key, value) => {
    const foundObjIndex = questions.findIndex((item) => item.id === id)
    const updateFields = { ...questions[foundObjIndex], [key]: value }

    setQuestions((prev) => {
      return prev.map((item, index) => {
        if (index === foundObjIndex) {
          return updateFields
        }
        return item
      })
    })
  }

  const toggleQuestion = (id) => {
    setQuestions((prev) => {
      const itemExist = prev?.find((item) => item.id === id)

      if (itemExist) {
        return prev.filter((item) => item.id !== id)
      } else {
        return [...prev, transformedQuestion?.find((item) => item.id === id)]
      }
    })
  }

  const validateRequiredFields = (fieldValues, requiredFields) => {
    const missingFields = requiredFields.filter((field) => {
      const value = fieldValues[field]

      if (Array.isArray(value)) {
        return value.length === 0
      } else if (typeof value === 'string') {
        return value.trim() === ''
      } else if (typeof value === 'boolean') {
        return false
      } else {
        return value == null || value === undefined
      }
    })

    if (missingFields.length > 0) {
      fail_notify(`Please fill in the following required fields: ${missingFields.join(', ')}`)
    }
    return true
  }

  const gotoStepTwo = () => {
    const requiredFields = [
      'company',
      'jobTitle',
      'jobType',
      'jobLocation',
      'employmentType',
      'seniorityLevel',
      'companyIndustry',
      'jobFunction',
      'description',
    ]
    if (fieldValues?.description.length < 50) {
      return fail_notify('Job description cant be less than 50 characters')
    }
    if (validateRequiredFields(fieldValues, requiredFields)) {
      setjobStep(jobSteps[1])
    } else {
      setjobStep(jobSteps[0])
    }
  }

  const onSubmit = async () => {
    const data = {
      //   ...fieldValues,
      isPublished: fieldValues.isPublished,
      title: fieldValues.jobTitle,
      description: fieldValues.description,
      location: fieldValues.jobLocation,
      levelOfExperience: fieldValues.seniorityLevel,
      jobType: fieldValues.jobType,
      qualification: 'BSC',
      tags: SkillOptions.map(({ value }) => value),
      industry: [fieldValues.companyIndustry],
      jobFunction: [fieldValues.jobFunction],
      emailTemplate: fieldValues.emailTemplate,
      company: fieldValues.company,
      siteToApply: fieldValues.siteToApply,
      emailToNotify: fieldValues.emailToNotify,
      isResumeRequired: fieldValues.isResumeRequired,
      isQualified: fieldValues.isQualified,
      jobScreeningAnswers: questions?.map((item) => ({
        questionId: item?.id,
        answer: item?.answer?.toLowerCase(),
        mustHave: item?.mustHave,
      })),
    }

    try {
      const res = await appApiCalls.postAJob(data)
      console.log(res)
      //    success_notify()
      setSuccessfulModal(true)
    } catch (error) {
      console.error(error)
      fail_notify('something went wrong')
      setSuccessfulModal(false)
    }
  }

  const gotoPage = (step) => setjobStep(jobSteps[step])
  return (
    <>
      <JobSuccessModal
        visible={successfulModal}
        setVisible={setSuccessfulModal}
        setActiveTap={setActiveTap}
      />
      <div className="p-4">
        <div className="d-flex gap-5">
          <CCol
            className="d-flex px-2 align-items-start justify-content-between flex-column bg-white rounded-2 gap-4 py-4"
            md={3}
            style={{ height: '200px' }}
          >
            {jobSteps.map((job, index) => {
              const isActive = jobStep === job
              return (
                <CButton
                  key={job}
                  size="lg"
                  onClick={() => {
                    index === 1 ? gotoStepTwo() : setjobStep(job)
                  }}
                  className={`text-small  w-100 rounded shadow-none lightGreen text-left d-flex align-items-center justify-content-start gap-2 ${
                    isActive ? 'text-white' : 'bg-white text-grey-700'
                  }`}
                >
                  {isActive ? <CheckedIcon /> : <UnCheckedIcon />}
                  <span>{job}</span>
                </CButton>
              )
            })}
          </CCol>
          <CCol className="rounded">
            {isLoading ? (
              <div>
                <ShowLoading />
              </div>
            ) : (
              <>
                {jobStep === jobSteps[0] && (
                  <JobOverview
                    fieldValues={fieldValues}
                    handleFieldChange={handleFieldChange}
                    gotoNext={gotoStepTwo}
                    skillValue={skillValue}
                    setSkillOptions={setSkillOptions}
                    setSkillValue={setSkillValue}
                    SkillOptions={SkillOptions}
                    onSubmit={onSubmit}
                  />
                )}
                {jobStep === jobSteps[1] && (
                  <JobScreeningQuestion
                    gotoPage={gotoPage}
                    fieldValues={fieldValues}
                    questions={questions}
                    toggleQuestion={toggleQuestion}
                    transformedQuestion={transformedQuestion}
                    handleAnswerChange={handleAnswerChange}
                    handleFieldChange={handleFieldChange}
                  />
                )}
                {jobStep === jobSteps[2] && (
                  <PreviewPublishJob
                    fieldValues={fieldValues}
                    skills={SkillOptions.map((skill) => skill.label)}
                    gotoPage={gotoPage}
                    onSubmit={onSubmit}
                  />
                )}
              </>
            )}
          </CCol>
        </div>
      </div>
    </>
  )
}

export default PostJob
