import {CButton, CCol, CForm, CFormCheck, CFormInput, CFormLabel, CRow} from '@coreui/react-pro'
import React, {useState} from 'react'
import TextEditor from '../TextEditor'
import CreatableSelect from 'react-select/creatable'
import {
    companyTypes,
    employmentTypes,
    experience,
    jobFunctionsOPtions,
    jobTypes,
} from 'src/constant/constants'
import CustomInput from '../CustomInput'
import {useGetAllStates} from 'src/hooks/user/assessmentApiHooks'

const JobOverview = ({
                         fieldValues,
                         gotoNext,
                         skillValue,
                         setSkillValue,
                         SkillOptions,
                         setSkillOptions,
                         handleFieldChange,
                     }) => {
    //   const {
    //     setValue,
    //     formState: { errors },
    //   } = useForm({ resolver: yupResolver({}) })
    const [emailToNotifyCheck, setEmailToNotifyCheck] = useState(false)
    const [siteToApplyCheck, setSiteToApplyCheck] = useState(false)

    const {data} = useGetAllStates()
    const citiesOptions = data?.data?.data?.map?.((state) => ({
        value: state.stateName,
        label: state?.stateName,
    }))

    const {
        company,
        jobTitle,
        employmentType,
        jobType,
        jobLocation,
        seniorityLevel,
        companyIndustry,
        jobFunction,
        siteToApply,
        emailToNotify,
        isResumeRequired,
        description,
    } = fieldValues

    const createOption = (label) => ({
        label,
        value: label,
    })

    const handleKeyDown = (event) => {
        if (!skillValue) return
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                setSkillOptions((prev) => [...prev, createOption(skillValue)])
                setSkillValue('')
                event.preventDefault()
                break
            default:
        }
    }

    return (
        <div className=" p-2 h-auto">
            <p className="text-black fs-3 fw-bolder">
                <strong>Job Overview</strong>
            </p>

            <CForm className="p-4 mt-2">
                <CRow className="">
                    <CCol className="">
                        {' '}
                        <CustomInput
                            name={'company'}
                            label={'Company'}
                            type="text"
                            placeholder="Outsource Global Technologies Limited"
                            value={company}
                            handleFieldChange={handleFieldChange}
                        />
                    </CCol>
                    <CCol>
                        <CustomInput
                            name={'jobTitle'}
                            label={'Job Title'}
                            type="text"
                            placeholder="Enter Job Title"
                            value={jobTitle}
                            handleFieldChange={handleFieldChange}
                        />
                    </CCol>
                </CRow>
                <CRow className="mt-4">
                    <CCol className="">
                        {' '}
                        <CustomInput
                            name="jobType"
                            label={'Job Type'}
                            type="select"
                            options={jobTypes.map((job) => ({
                                value: job.title,
                                label: job.title,
                            }))}
                            placeholder="Select Job Type"
                            value={jobType}
                            handleFieldChange={handleFieldChange}
                        />
                    </CCol>
                    <CCol>
                        <CustomInput
                            name="jobLocation"
                            label={'Job Location'}
                            type="select"
                            options={citiesOptions}
                            placeholder="Select Job Location"
                            value={jobLocation}
                            handleFieldChange={handleFieldChange}
                        />
                    </CCol>
                </CRow>
                <CRow className="mt-4">
                    <CCol className="">
                        {' '}
                        <CustomInput
                            name="employmentType"
                            label={'Employment Type'}
                            type="select"
                            placeholder="Select Employment Type"
                            options={employmentTypes}
                            value={employmentType}
                            handleFieldChange={handleFieldChange}
                        />
                    </CCol>
                    <CCol>
                        <CustomInput
                            name="seniorityLevel"
                            label={'Seniority Level'}
                            type="select"
                            options={experience?.map((level) => ({
                                value: level.title,
                                label: level.title,
                            }))}
                            placeholder="Select Seniority Level"
                            value={seniorityLevel}
                            handleFieldChange={handleFieldChange}
                        />
                    </CCol>
                </CRow>
                <CRow className="mt-4">
                    <CCol className="">
                        {' '}
                        <CustomInput
                            name="companyIndustry"
                            label={'Company Industry (Select up to 5)'}
                            type="select"
                            options={companyTypes}
                            placeholder="Industry"
                            value={companyIndustry}
                            handleFieldChange={handleFieldChange}
                        />
                    </CCol>
                    <CCol>
                        <CustomInput
                            name="jobFunction"
                            label={'Job Function (Select up to 3)'}
                            type="select"
                            placeholder="FullStack"
                            value={jobFunction}
                            options={jobFunctionsOPtions}
                            handleFieldChange={handleFieldChange}
                        />
                    </CCol>
                </CRow>

                <div className="mt-4 overflow-y-auto" style={{height: 'auto'}}>
                    <CFormLabel htmlFor={'purposeObjectives'} className="fw-medium mb-0 labelColor">
                        Add Description
                    </CFormLabel>
                    <TextEditor
                        name={`description`}
                        editorState={description}
                        setEditorState={(content) => handleFieldChange({name: 'description', value: content})}
                    />
                </div>

                <CRow className="mt-4">
                    <p className=" fs-6 fw-bolder">
                        <strong>Add Skills </strong>
                        <span className="text-grey-700">(Up to 10)</span>
                    </p>
                    <p className="text-grey-700 small">
                        These skills will display on the job post and be used to expose the listing to relevant
                        applicants.
                    </p>

                    <CreatableSelect
                        name={'skills'}
                        isMulti
                        isClearable
                        onChange={(newValue) => setSkillOptions(newValue)}
                        onInputChange={(newValue) => setSkillValue(newValue)}
                        onKeyDown={handleKeyDown}
                        value={SkillOptions}
                        inputValue={skillValue}
                        className="mt-2"
                        menuIsOpen={false}
                    />
                </CRow>

                <div className="mt-4 p-2">
                    <p className=" fs-6 fw-bolder">
                        <strong>How Would You Like To Receive Your Applicants? </strong>
                    </p>
                    <p className="text-grey-700 small">
                        <strong>Recommended:</strong> Let candidates apply with their Worknation profile and
                        attach their resume. You get notified by email.
                    </p>

                    <CRow className="mt-4">
                        <CCol className="d-flex flex-column justify-content-between">
                            <CFormCheck
                                type="radio"
                                id="emailToNotifyCheck"
                                label="Recommended: Let candidates apply with their Worknation profile and attach their resume. You get notified by email."
                                name="emailToNotifyCheck"
                                checked={emailToNotifyCheck}
                                onChange={() => {
                                    setSiteToApplyCheck(false)
                                    setEmailToNotifyCheck(!emailToNotifyCheck)
                                }}
                                className=""
                            />

                            <CFormInput
                                type="email"
                                id={'emailToNotify'}
                                disabled={!emailToNotifyCheck}
                                name={'emailToNotify'}
                                onChange={(e) => handleFieldChange({name: e.target.name, value: e.target.value})}
                                value={emailToNotify}
                                placeholder={'example@domain.com'}
                                aria-describedby={'recommended'}
                                className="py-2 inputBorder mt-1"
                            />
                        </CCol>
                        <CCol className="d-flex flex-column justify-content-between">
                            <CFormCheck
                                type="radio"
                                name="emailToNotifyCheck"
                                id="siteToApplyCheck"
                                onChange={() => {
                                    setEmailToNotifyCheck(false)
                                    setSiteToApplyCheck((p) => !p)
                                }}
                                label="Direct applicants to a different site to apply"
                                // name="siteToApplyCheck"
                                checked={siteToApplyCheck}
                            />
                            <CFormInput
                                type="text"
                                id={'siteToApply'}
                                name={'siteToApply'}
                                inpu={siteToApply}
                                disabled={!siteToApplyCheck}
                                onChange={(e) => handleFieldChange({name: e.target.name, value: e.target.value})}
                                placeholder={'domain.com'}
                                aria-describedby={'siteToApply'}
                                className="py-2 inputBorder  mt-1"
                            />
                        </CCol>
                    </CRow>
                </div>
                <CFormCheck
                    id="isResumeRequired"
                    label="Require applicants to attach a resume"
                    name="isResumeRequired"
                    checked={isResumeRequired}
                    onChange={(e) => handleFieldChange({name: e?.target?.name, value: e?.target?.value})}
                />

                <CRow className="mt-5  d-flex justify-content-end align-items-center gap-3">
                    {/* <CButton
            className="bg-transparent shadow-none"
            style={{ color: '#1FAB89', width: '200px', fontSize: '1rem', border: 'none' }}
          >
            SAVE AS DRAFT
          </CButton> */}
                    <CButton
                        className="bg-transparent shadow-none"
                        style={{color: '#1FAB89', width: '200px', fontSize: '1rem'}}
                        onClick={() => gotoNext(2)}
                    >
                        PREVIEW
                    </CButton>
                    <CButton
                        style={{background: '#1FAB89', width: '250px', fontSize: '1rem'}}
                        size="lg"
                        className="text-white"
                        onClick={() => {
                            gotoNext()
                        }}
                    >
                        SAVE & CONTINUE
                    </CButton>
                </CRow>
            </CForm>
        </div>
    )
}

export default JobOverview
